<div *ngIf="product">
    <app-page-banner-container headingLevel="1" backgroundImage="/assets/images/default_abstract.jpg" [title]="product.title"
        pageLink="/about-us" [breadcrumbs]="breadcrumbs"></app-page-banner-container>
    <!-- <h2>{{ categoryData.name }}</h2>
    <p>{{ categoryData.description }}</p>
    <img *ngIf="categoryData.page_title_image" [src]="categoryData.page_title_image" alt="{{ categoryData.name }}"> -->
</div>

<div class="container" *ngIf="product">
    <div class="row ptb-30">

        <div class="col-lg-6 col-12 order-lg-1 order-2">
            <!-- <h1>{{product?.title}}</h1> -->
            <div class="d-flex flex-column" *ngIf="!product.ecommerce_enabled && product.upc">
                <div class="d-flex flex-row">
                    <small>Item: {{product.upc}}</small>
                </div>
            </div>
            <p *ngIf="product.description" [innerHtml]="product.description"></p>
            <app-features-container [features]="product.features"></app-features-container>
            <app-benefits-container [benefits]="product.usp_tags" [benefitsArray]="product.benefits"></app-benefits-container>
            <div>
                <app-helpful-resources-container [resources]="resources"></app-helpful-resources-container>
            </div>
        </div>
        <div class="col-lg-6 col-12 order-lg-2 order-1">
            <div class="pb-30"><app-product-carousel-container
                    [productSlug]="product.slug"></app-product-carousel-container></div>

            <app-request-product-information-container [productName]="product.product_name" [pageName]="pageName"
                [pageUri]="pageUri"></app-request-product-information-container>

        </div>
    </div>
    </div>
    <app-testimonials-list-container [productSlug]="product.slug" [title]="product.product_name"
        subtitle=" Testimonials"></app-testimonials-list-container>


    <app-related-products-container [relatedProducts]="product.recommended_products" title="Lab Automation Solutions
        Related to {{product.product_name}}"></app-related-products-container>