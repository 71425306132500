import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { StickyNavModule } from 'ng2-sticky-nav';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { Action, ActionReducerMap, MetaReducer, StoreModule } from '@ngrx/store';
// import { AppStoreModule } from './store/app-store.module';
import { FeaturesModule } from './features/features.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AlertModule } from 'ngx-bootstrap/alert';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { HubspotAdapterService } from './core/services/products/hubspot-adapter.service';
import { ProductRequestService } from './core/services/products/product-request.service';
import { RouterModule } from '@angular/router';
import { JobApplicationService } from './core/services/careers/job-application.service';
import { JobApplicationHubspotAdapterService } from './core/services/careers/job-application-hubspot-adapter.service';
import { SEARCH_SERVICE } from './core/services/search/abstract-search.service';
import { AlgoliaSearchService } from './core/services/search/algolia-search.service';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { OverlayModule } from '@angular/cdk/overlay';
import { DemoRequestService } from './core/services/demo-request/demo-request.service';
import { DemoRequestHubspotAdapterService } from './core/services/demo-request/demo-request-hubspot-adapter.service';
import { environment } from '../environments/environment';
import { CanonicalService } from './core/services/seo/canonical.service';
import { GtmService } from './core/services/gtm.service';
import { CookieService } from 'ngx-cookie-service';
import {
  AuthService,
  AUTH_CONFIG_TOKEN,
  AuthConfig,
  ConfigService,
  AppStoreModule,
  membershipsReducer,
  tenantsReducer,
  FluentCoreLibModule,
  accessControlReducer,
  spinnerReducer,
  tenantTemplateReducer,
  createLocalStorageMetaReducer,
  libAppReducers,
  MENU_CONFIG,
  clearStateMetaReducer,
  FORM_PERMISSIONS_CONFIG_TOKEN
} from '@fluentllc/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { loadConfigData } from './core/utils/config';
import { multiStepFormReducer } from './shared/modules/multi-step-form/store/multi-step-form.reducer';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { HeaderLoggingInterceptor } from './core/interceptors/header-logging.interceptor';
import { CacheInterceptor } from './core/interceptors/cache.intercepter';
import { ServiceWorkerModule } from '@angular/service-worker';
const reducers: ActionReducerMap<any> = {
  // ...libAppReducers
};
library.add(faStar);

const metaReducers: MetaReducer<any, Action>[] = [
  createLocalStorageMetaReducer<any, Action>({ localStorageKey: environment.localStorageKey, localStorageEncrypt: environment.encryptLocalStorage, localStorageSecretKey: environment.localStorageSecretKey, version: environment.version}),
  clearStateMetaReducer
];

const authConfig: AuthConfig = {
  apiUrl: environment.apiUrl,
  localStorageKey: environment.localStorageKey,
  localStorageSecretKey: environment.localStorageSecretKey,
  redirectUrlAfterLogin: environment.redirectUrlAfterLogin,
  redirectUrlAfterLogout: environment.redirectUrlAfterLogout,
};

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

const customFormlyFields: FormlyFieldConfig[] = [
  {
    key: 'custom_permission',
    type: 'input',
    templateOptions: {
      label: 'Custom Permission',
      required: true,
    }
  }
];


@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    CoreModule,
    FluentCoreLibModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    StickyNavModule,
    FontAwesomeModule,
    AppStoreModule,
    StoreModule.forRoot(reducers, { 
      metaReducers: metaReducers,
    }),
    StoreModule.forFeature('multiStepForm', multiStepFormReducer),
    EffectsModule.forRoot([]),
    environment.production ? [] : StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    FeaturesModule,
    BrowserAnimationsModule,
    ModalModule.forRoot(),
    AlertModule.forRoot(),
    TooltipModule.forRoot(),
    OverlayModule,
    FormlyModule.forRoot(),
    ReactiveFormsModule,
    FormlyBootstrapModule,
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    AuthService,
    { provide: AUTH_CONFIG_TOKEN, useValue: authConfig },
    {
      provide: APP_INITIALIZER,
      useFactory: loadConfigData,
      deps: [HttpClient, ConfigService],
      multi: true,
    },
    {
      provide: MENU_CONFIG,
      useValue: {
        config: {},
      }
    },
    ConfigService,
    { provide: FORM_PERMISSIONS_CONFIG_TOKEN, useValue: customFormlyFields },
    { provide: ProductRequestService, useClass: HubspotAdapterService },
    { provide: JobApplicationService, useClass: JobApplicationHubspotAdapterService },
    { provide: DemoRequestService, useClass: DemoRequestHubspotAdapterService },
    { provide: SEARCH_SERVICE, useClass: AlgoliaSearchService },
    { provide: 'gtmId', useValue: environment.gtm_id },
    GtmService,
    CanonicalService,
    CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
