import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Category } from '../../models/category.interface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { loadCategoryFormState, saveCategoryFormState } from '../../store/category.actions';
import { SeoData } from 'src/app/shared/models/seo-data.interface';

@Component({
  selector: 'app-category-admin-manage',
  templateUrl: './category-admin-manage.component.html',
  styleUrls: ['./category-admin-manage.component.scss']
})
export class CategoryAdminManageComponent implements OnInit {
  @Input() category: Category;
  @Output() save = new EventEmitter<Category>();
  @Output() cancel = new EventEmitter<void>();
  isEditMode: boolean = false;
  categoryForm: FormGroup;
  public editorDisabled = false;
  isSettingValue = false;
  isFormValid: boolean = true;

  config: any = {
    airMode: false,
    tabDisable: true,
    popover: {
      table: [
        ["add", ["addRowDown", "addRowUp", "addColLeft", "addColRight"]],
        ["delete", ["deleteRow", "deleteCol", "deleteTable"]],
      ],
      image: [
        ["image", ["resizeFull", "resizeHalf", "resizeQuarter", "resizeNone"]],
        ["float", ["floatLeft", "floatRight", "floatNone"]],
        ["remove", ["removeMedia"]],
      ],
      link: [["link", ["linkDialogShow", "unlink"]]],
      air: [["font", ["bold", "italic", "underline", "strikethrough", "superscript", "subscript", "clear"]]],
    },
    height: "600px",
    uploadImagePath: "/api/upload",
    toolbar: [
      ["misc", ["codeview", "undo", "redo", "codeBlock"]],
      ["font", ["bold", "italic", "underline", "strikethrough", "superscript", "subscript", "clear"]],
      ["style", ["style"]],
      ["fontsize", ["fontname", "fontsize", "color"]],
      ["para", ["style0", "ul", "ol", "paragraph", "height"]],
      ["insert", ["table", "picture", "link", "video", "hr"]],
      ["customButtons", ["testBtn"]],
    ],
    codeviewFilter: false,
    codeviewFilterRegex:
      /<\/*(?:applet|b(?:ase|gsound|link)|embed|frame(?:set)?|ilayer|l(?:ayer|ink)|meta|object|s(?:cript|tyle)|t(?:itle|extarea)|xml|.*onmouseover)[^>]*?>/gi,
    codeviewIframeFilter: true,
    styleTags: ["p", "h1", "h2", "h3", "h4", "h5", "h6", "pre", "blockquote"],
    fontNames: ["Poppins", "Heebo", "Nunito Sans", "sansans-serif"],
    fontNamesIgnoreCheck: ["Poppins", "Heebo", "Nunito Sans"],
    styleWithSpan: false,
    dialogsInBody: true
  };

  get f() {
    return this.categoryForm.controls;
  }

  constructor(private fb: FormBuilder, private store: Store, private cdr: ChangeDetectorRef) {
    this.categoryForm = this.fb.group({
      name: ["", Validators.required],
      description: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    if (this.category && this.category.id) {
      this.isEditMode = true;
      this.categoryForm.patchValue(
        {
          name: this.category.name,
          description: this.category.description,
          slug: this.category.slug,
        }
      );
    }

    this.categoryForm.valueChanges.subscribe((category) => {
      if (!this.isSettingValue) {
        this.store.dispatch(saveCategoryFormState({ category }));
      }
    });

    this.store.dispatch(loadCategoryFormState());

    // Select the formState from the store and use it to set the form values
    this.store.pipe(select(select)).subscribe((formState) => {
      if (formState) {
        this.isSettingValue = true; // Add this line
        this.categoryForm.patchValue(formState);
        this.isSettingValue = false; // Add this line
      }
    });
    this.cdr.detectChanges();
  }

  onSave() {
    if (this.categoryForm.valid) {
      const formValue = { ...this.categoryForm.value };

      // const solutionTagJson = {
      //   solution_tag_ids: this.solutionTagIds
      // }
      // this.product.specifications = this.specifications;
      // this.product.features = this.product.features;
      const updatedCategory = {
        ...this.category,
        ...formValue
      };

      this.save.emit(updatedCategory);
    }
  }

  onSeoDataChange(seoData: SeoData) {
    this.category = {
      ...this.category,
      ...seoData,
    };
  }

  onCancel() {
    this.cancel.emit();
  }

}
