<div *ngIf="product">
    <app-page-banner-container headingLevel="1" backgroundImage="/assets/images/default_abstract.jpg" [title]="product.title"
        pageLink="/about-us" [breadcrumbs]="breadcrumbs"></app-page-banner-container>
    <!-- <h2>{{ categoryData.name }}</h2>
    <p>{{ categoryData.description }}</p>
    <img *ngIf="categoryData.page_title_image" [src]="categoryData.page_title_image" alt="{{ categoryData.name }}"> -->
</div>

<div class="container">
    <div class="row ptb-75">

        <div class="col-lg-6 col-12 order-lg-1 order-2">
            <div class="d-flex flex-column" *ngIf="!product.ecommerce_enabled && product.upc">
                <div class="d-flex flex-row">
                    <small>Item Number: {{product.upc}}</small>
                </div>
            </div>
            <p *ngIf="product.description" [innerHtml]="product.description"></p>
            <app-features-container [features]="product.features"></app-features-container>
            <app-benefits-container [benefits]="product.usp_tags"></app-benefits-container>
            <div class="pt-30">
                <app-helpful-resources-container [resources]="resources"></app-helpful-resources-container>
                <app-call-to-action-small-with-icon1-container ctaTitle="Order Form" ctaUrl="assets/Tube%20Order%20Form.pdf">
                </app-call-to-action-small-with-icon1-container>
            </div>
        </div>
        <div class="col-lg-6 col-12 order-lg-2 order-1">
            <div class="pb-30"><app-product-carousel-container
                    [productSlug]="product.slug"></app-product-carousel-container></div>
                    <div class="box-shadow">
                    <div class="section-title with-desc-clearfix">
                        <div class="title-header">
                            <h5>Request Product Information</h5>
                            <h4 class="title">Ask Our Experts About the {{product.title}} </h4>
                        </div>
                    </div>
                    <div id="hubspotForm"></div>
                </div>
            

        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <section [ngClass]="{'pb-5': !product.specifications?.length }" class="sci-row specifications clearfix">
                <div *ngIf="product.specifications?.length" class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="pb-50">
                                <div class="sci-tabs sci-tab-style-horizontal" data-effect="fadeIn">
                                    <ul class="tabs clearfix">
                                        <li class="tab active" *ngIf="product.specifications?.length"><a href="#"><i
                                                    class="flaticon flaticon-legal"></i>{{product.product_name}}
                                                Specifications</a>
                                        </li>
                                        <!--
                              <li class="tab"><a href="#"><i class="flaticon flaticon-legal"></i>Accessories</a></li>
                              <li class="tab"><a href="#"><i class="flaticon flaticon-legal"></i>Service & Support</a></li>
                            -->
                                    </ul>
                                    <div class="content-tab sci-bgcolor-white">
                                        <!-- content-inner -->
                                        <div class="content-inner active">
                                            <div class="row">
                                                <div class="col-lg-7 col-md-10 col-sm-9">
                                                    <div class="res-575-mt-15">
                                                        <table class="table table-bordered mb-40">
                                                            <tbody>
                                                                <tr *ngFor="let spec of product.specifications">
                                                                    <th scope="row">{{spec.name}}:</th>
                                                                    <th>{{spec.value}}</th>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <div *ngIf="product.symbologies?.length">
                                                            <h6>Enabled Symbologies</h6>
                                                            <p>Scinomix implements the following symbologies:</p>
                                                            <ul class="pl-25">
                                                                <li *ngFor="let symbol of product.symbologies">
                                                                    {{symbol.name}}
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div><!-- content-inner end-->
                                        <!-- content-inner -->
                                        <div class="content-inner">
                                            <div class="row">
                                                <div class="col-lg-12 col-md-12 col-sm-12">
                                                    <h5>Could be used for ancillary sales</h5>
                                                </div>
                                            </div>
                                        </div><!-- content-inner end-->
                                        <!-- content-inner -->
                                        <div class="content-inner">
                                            <div class="row">
                                                <div class="col-lg-8">
                                                    <div class="mb-30">
                                                        <!-- section title -->
                                                        <div class="section-title">
                                                            <div class="title-header">
                                                                <h2 class="title">Frequently Asked Questions</h2>
                                                            </div>
                                                            <div class="title-desc">Below you’ll find answers to some of
                                                                the most frequently asked questions
                                                                at Tectxon. We are constantly adding most asked question
                                                                to this page so if you have a
                                                                question and don’t see your answer, don’t hesitate to
                                                                email us at <a href="#">info@scinomix.com</a></div>
                                                        </div><!-- section title end -->
                                                    </div>
                                                    <div class="accordion">
                                                        <!-- toggle -->
                                                        <div
                                                            class="toggle sci-style-classic sci-toggle-title-bgcolor-grey sci-control-right-true">
                                                            <div class="toggle-title">
                                                                <a href="#" class="active">How do I adjust the gripper
                                                                    on the Sci-Print VX2?</a>
                                                            </div>
                                                            <div class="toggle-content">
                                                                <p>The small knob that controls gripper air pressure is
                                                                    located on the underside of the upper
                                                                    gantry (see image).</p>
                                                                <p>
                                                                    Note: If you have a new VX2, the knob may look
                                                                    slightly different
                                                                </p>
                                                                <p>
                                                                    It is important to make sure the gripper air
                                                                    pressure is not too high and not too low. If
                                                                    you find that the gripper is not picking up the
                                                                    tube, this could mean your air pressure is
                                                                    too low, so you may need to adjust it to make it
                                                                    stronger. If the gripper air pressure is
                                                                    too strong, this could result in the grippers
                                                                    closing too tight, and the tube breaking
                                                                </p>
                                                                <p>
                                                                    To adjust gripper air pressure:
                                                                </p>
                                                                <ol>
                                                                    <li>Unscrew the lock collar completely by turning it
                                                                        to the left (lock collar is located
                                                                        on
                                                                        the gripper air pressure knob.)</li>

                                                                    <li>Once the lock collar is undone, you will be able
                                                                        to adjust the knob to control the
                                                                        air
                                                                        pressure</li>

                                                                    <li>To increase air pressure, tighten the knob, or
                                                                        turn right</li>

                                                                    <li>To decrease air pressure, loosen the knob, or
                                                                        turn left</li>
                                                                </ol>
                                                            </div>
                                                        </div><!-- toggle end -->
                                                        <!-- toggle -->
                                                        <div
                                                            class="toggle sci-style-classic sci-toggle-title-bgcolor-grey sci-control-right-true">
                                                            <div class="toggle-title"><a href="#">What is a medical
                                                                    laboratory or lab?</a></div>
                                                            <div class="toggle-content">
                                                                <p>Lorem Ipsum is simply dummy text of the printing and
                                                                    typesetting industry. Lorem Ipsum has
                                                                    been the industry. It has survived not only five
                                                                    centuries, but also the leap into
                                                                    electronic typesetting.</p>
                                                            </div>
                                                        </div><!-- toggle end -->
                                                        <!-- toggle -->
                                                        <div
                                                            class="toggle sci-style-classic sci-toggle-title-bgcolor-grey sci-control-right-true">
                                                            <div class="toggle-title"><a href="#">Who performs the
                                                                    testing on laboratory
                                                                    specimens?</a></div>
                                                            <div class="toggle-content">
                                                                <p>Lorem Ipsum is simply dummy text of the printing and
                                                                    typesetting industry. Lorem Ipsum has
                                                                    been the industry. It has survived not only five
                                                                    centuries, but also the leap into
                                                                    electronic typesetting.</p>
                                                            </div>
                                                        </div><!-- toggle end -->
                                                        <!-- toggle -->
                                                        <div
                                                            class="toggle sci-style-classic sci-toggle-title-bgcolor-grey sci-control-right-true">
                                                            <div class="toggle-title"><a href="#">What can I do with The
                                                                    Laboratory?</a></div>
                                                            <div class="toggle-content">
                                                                <p>Lorem Ipsum is simply dummy text of the printing and
                                                                    typesetting industry. Lorem Ipsum has
                                                                    been the industry. It has survived not only five
                                                                    centuries, but also the leap into
                                                                    electronic typesetting.</p>
                                                            </div>
                                                        </div><!-- toggle end -->
                                                        <!-- toggle -->
                                                        <div
                                                            class="toggle sci-style-classic sci-toggle-title-bgcolor-grey sci-control-right-true">
                                                            <div class="toggle-title"><a href="#">Can I request my own
                                                                    laboratory tests?</a></div>
                                                            <div class="toggle-content">
                                                                <p>Lorem Ipsum is simply dummy text of the printing and
                                                                    typesetting industry. Lorem Ipsum has
                                                                    been the industry. It has survived not only five
                                                                    centuries, but also the leap into
                                                                    electronic typesetting.</p>
                                                            </div>
                                                        </div><!-- toggle end -->
                                                        <!-- toggle -->
                                                        <div
                                                            class="toggle sci-style-classic sci-toggle-title-bgcolor-grey sci-control-right-true">
                                                            <div class="toggle-title"><a href="#">Do I need an
                                                                    appointment to come to the laboratory?</a>
                                                            </div>
                                                            <div class="toggle-content">
                                                                <p>Lorem Ipsum is simply dummy text of the printing and
                                                                    typesetting industry. Lorem Ipsum has
                                                                    been the industry. It has survived not only five
                                                                    centuries, but also the leap into
                                                                    electronic typesetting.</p>
                                                            </div>
                                                        </div><!-- toggle end -->
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 col-md-5">
                                                    <div
                                                        class="sci-bgcolor-grey sci-bg pt-25 pr-30 pb-30 pl-30 res-991-mt-30">
                                                        <div class="sci-col-wrapper-bg-layer sci-bg-layer"></div>
                                                        <div class="layer-content">
                                                            <!-- section title -->
                                                            <div class="section-title">
                                                                <div class="title-header">
                                                                    <h2 class="title">Submit Question</h2>
                                                                </div>
                                                                <div class="title-desc">Fill out the information below
                                                                    and one of our account managers will
                                                                    respond to your request.</div>
                                                            </div><!-- section title end -->
                                                            <form id="sci-contactform"
                                                                class="sci-contactform wrap-form clearfix" method="post"
                                                                action="#">
                                                                <div class="row">
                                                                    <div class="col-lg-12">
                                                                        <label>
                                                                            <span class="text-input"><input
                                                                                    name="your-name" type="text"
                                                                                    value="" placeholder="Your Name"
                                                                                    required="required"></span>
                                                                        </label>
                                                                        <label>
                                                                            <span class="text-input"><input name="email"
                                                                                    type="email" value=""
                                                                                    placeholder="Email"
                                                                                    required="required"></span>
                                                                        </label>
                                                                        <label>
                                                                            <span class="text-input"><input name="phone"
                                                                                    type="text" value=""
                                                                                    placeholder="Phone Number"
                                                                                    required="required"></span>
                                                                        </label>
                                                                        <label>
                                                                            <span class="text-input"><textarea
                                                                                    name="message" rows="4" cols="40"
                                                                                    placeholder="Message"
                                                                                    required="required"></textarea></span>
                                                                        </label>
                                                                        <label>
                                                                            <button
                                                                                class="submit sci-btn sci-btn-size-md sci-btn-shape-rounded sci-btn-style-fill sci-btn-color-skincolor"
                                                                                type="submit">Submit</button>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div><!-- content-inner end-->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
    <app-related-products-container [relatedProducts]="product.recommended_products" title="Lab Automation Solutions
        Related to {{product.product_name}}"></app-related-products-container>