<div class="page-banner-area ptb-30 four" [ngStyle]="{
    'background-image': backgroundImage ? 'url(' + backgroundImage + ')' : ''
  }">
    <div class="container">
        <div class="page-banner-content">
            <ng-container [ngSwitch]="headingLevel">
                <h1 *ngSwitchCase="'1'">{{ title }}</h1>
                <h2 *ngSwitchCase="'2'">{{ title }}</h2>
                <div class="banner-heading" *ngSwitchDefault>{{ title }}</div>
                <h3 *ngSwitchCase="'3'">{{ title }}</h3>
                <h4 *ngSwitchCase="'4'">{{ title }}</h4>
                <h5 *ngSwitchCase="'5'">{{ title }}</h5>
                <h6 *ngSwitchCase="'6'">{{ title }}</h6>
            </ng-container>
            <app-breadcrumb-container [breadcrumbs]="breadcrumbs"></app-breadcrumb-container>
        </div>
    </div>
</div>