import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { MetaTagsService } from 'src/app/core/services/seo/meta-tags.service';
import { Category } from '../../models/category.interface';
import { AppState } from "src/app/store/app.state";
import { Store } from '@ngrx/store';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import * as CategoryActions from '../../store/category.actions';
import { selectCategoryById, selectCategoryForEditingById } from '../../store/category.selectors';
@Component({
  selector: 'app-category-admin-manage-container',
  templateUrl: './category-admin-manage-container.component.html',
  styleUrls: ['./category-admin-manage-container.component.scss']
})
export class CategoryAdminManageContainerComponent implements OnInit {
  category$: Observable<Category>;
  isEditMode: boolean = false;

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private router: Router,
    private metaTagsService: MetaTagsService,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Check if the current URL is '/admin/post/create'
        if (this.router.url === '/admin/products/category/create') {
          // Dispatch the clearPostFormState action
          this.store.dispatch(CategoryActions.clearCategoryFormState());
        }
      }
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      const categoryId = Number(params['id']);
      let categoryTitle = 'Create Category';
      if (categoryId) {
        this.isEditMode = true;
        categoryTitle = 'Edit Category Text';
        // this.category$ = this.store.select(selectCategoryById(categoryId));
        this.store.dispatch(CategoryActions.loadCategories());
        this.category$ = this.store.select(selectCategoryForEditingById(categoryId));
      } else {
        this.isEditMode = false;
        this.category$ = new Observable<Category>(); // Initialize with a new post
      }


      const newMetaTags = {
        title: categoryTitle,
        description: "",
        keywords: "", // Set this to any keywords you want to use, or add a property to the ProductCategory interface for keywords
      };
      this.metaTagsService.updateMetaTags(newMetaTags);
    });
  }

  onSave(category: Category): void {
    this.store.dispatch(CategoryActions.saveCategoryFormState({ category: category}))
    if (this.isEditMode) {
      console.log(category)
      this.store.dispatch(CategoryActions.updateCategory({ category }));
    } 
    this.store.dispatch(CategoryActions.clearCategoryFormState());
  }

  onCancel(): void {
    this.router.navigate(['/admin/products/categories']);
    this.store.dispatch(CategoryActions.clearCategoryFormState());
  }

}
